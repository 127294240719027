<template>
  <div v-if="mobileInSelect && isMobile" class="mb-20">
    <VSelect
      :options="getSelectedItems"
      :value="getSelectValue"
      :allow-empty="false"
      not-search
      name="navItem"
      style="max-width: 425px"
      @change="onSelectedTab"
    />
  </div>
  <TabsSwiper v-else class="tabs-swiper--border mb-30" :space-between="40">
    <NavItem
      class="swiper-slide"
      :class="classNameNavItem"
      v-for="(tab, index) in tabsList"
      :key="index"
      :navItem="tab"
      :is-disabled="isLoading"
    />
  </TabsSwiper>
</template>

<script>
import NavItem from '@/components/ui/Nav/NavItem'
import TabsSwiper from '@/components/ui/TabsSwiper/TabsSwiper'
import { mapState } from 'vuex'
import VSelect from '@/components/Form/VSelect.vue'
import { goToPage } from '@/utils/router'

export default {
  name: 'NavTabsSwiper',
  components: {
    VSelect,
    NavItem,
    TabsSwiper
  },
  props: {
    tabsList: {
      type: Array,
      required: true
    },
    classNameNavItem: {
      type: String,
      required: false,
      default: 'tab-item--main'
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    mobileInSelect: {
      type: Boolean,
      default: false
    },
    showCountInSelect: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    selectedItems: []
  }),

  computed: {
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),

    getSelectedItems () {
      const selectedItems = []
      this.tabsList.forEach(item => {
        selectedItems.push({
          ...item,
          name: item.label + (this.showCountInSelect ? ' (' + item.count + ')' : '')
        })
      })

      return selectedItems
    },

    getSelectValue () {
      const currentItem = this?.tabsList?.find(item => item.routeName === this.$route.name && (item.routeParams ? JSON.stringify(item.routeParams) === JSON.stringify(this.$route.params) : true))
      if (currentItem.label) {
        currentItem.name = currentItem.label + (this.showCountInSelect ? ' (' + currentItem.count + ')' : '')
      }
      return currentItem
    }
  },

  methods: {
    onSelectedTab (item) {
      if (item?.routeName) {
        return goToPage(item.routeName, item.routeParams ?? null)
      }
    }
  }
}
</script>
