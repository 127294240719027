<template>
  <div>
    <NavTabsSwiper
      :tabs-list="tabsOrderList"
      :is-loading="isLoadingOrderList"
      mobile-in-select
      show-count-in-select
    />

    <router-view />
  </div>
</template>

<script>
import NavTabsSwiper from '@/shared/ui/tabs/NavTabsSwiper.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'OrderList',
  components: {
    NavTabsSwiper
  },

  computed: {
    ...mapGetters('tabsOrderList', ['tabsOrderList']),

    ...mapState('order', ['isLoadingOrderList'])
  }
}
</script>
